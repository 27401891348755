.chart {
  margin: 20px 0 20px 40px;
  box-shadow: 0 2px 4px #0000000f;
}

.filters-box {
  contain: content;
  justify-content: space-evenly;
  display: flex;
}

.filters {
  contain: content;
  padding: 10px 20px;
}

.column {
  float: left;
  padding: 0 10px;
}

.column label {
  display: block;
}

p {
  padding-top: 20px;
  padding-left: 50px;
}

.days {
  width: 120px;
}
/*# sourceMappingURL=index.0f2cd794.css.map */
